import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89')
];

export const server_loads = [0,2,5,8,13,14,15,16];

export const dictionary = {
		"/": [~19],
		"/admin": [~20,[2],[3]],
		"/admin/audit-log": [~24,[2],[3]],
		"/admin/labs": [~25,[2],[3]],
		"/admin/materials": [~26,[2],[3]],
		"/admin/permissions": [~27,[2],[3]],
		"/admin/[distChannel]/materials": [~21,[2],[3]],
		"/admin/[distChannel]/materials/[code]": [~22,[2],[3]],
		"/admin/[distChannel]/prices": [~23,[2],[3]],
		"/api/orders/[orderId]/[eye]/lens/profile": [~28],
		"/api/v1/docs": [~29],
		"/billing": [~30,[4]],
		"/billing/[distChannel]": [~31,[4]],
		"/billing/[distChannel]/orders": [~32,[4]],
		"/billing/[distChannel]/orders/[orderId]": [~33,[4]],
		"/clinic": [~34,[5]],
		"/clinic/admin": [~35,[5]],
		"/clinic/orders": [~36,[5]],
		"/clinic/orders/[orderId]": [~37,[5]],
		"/clinic/orders/[orderId]/reorder": [~39,[5]],
		"/clinic/orders/[orderId]/[eye]/lens/update": [~38,[5]],
		"/clinic/tools": [~40,[5]],
		"/clinic/tools/toric-cross-cylinder": [~41,[5,6]],
		"/distributor": [~42,[7]],
		"/distributor/[distChannel]": [~43,[7,8]],
		"/distributor/[distChannel]/calculators": [~44,[7,8]],
		"/distributor/[distChannel]/calculators/practices": [~45,[7,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]": [~46,[7,8]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/forge": [~47,[7,8,9]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step1": [~48,[7,8,10]],
		"/distributor/[distChannel]/calculators/practices/[practiceId]/scleral/step2": [~49,[7,8,11]],
		"/distributor/[distChannel]/diagnostic": [~50,[7,8]],
		"/distributor/[distChannel]/labels": [~51,[7,8]],
		"/distributor/[distChannel]/orders": [~52,[7,8]],
		"/distributor/[distChannel]/orders/create": [~57,[7,8]],
		"/distributor/[distChannel]/orders/create/practices": [~58,[7,8]],
		"/distributor/[distChannel]/orders/create/practices/[practiceId]": [~59,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]": [~53,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/new": [~56,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/spec/[rowNumber]": [~54,[7,8]],
		"/distributor/[distChannel]/orders/[orderId]/[eye]/lens/update": [~55,[7,8]],
		"/distributor/[distChannel]/practices": [~60,[7,8]],
		"/distributor/[distChannel]/practices/[practiceId]": [~61,[7,8]],
		"/distributor/[distChannel]/tools": [~62,[7,8]],
		"/distributor/[distChannel]/tools/toric-cross-cylinder": [~63,[7,8,12]],
		"/kb": [~64,[13]],
		"/kb/search": [~66,[13]],
		"/kb/[slug]": [~65,[13,14]],
		"/lab": [~67],
		"/lab/[labCode]": [68,[15]],
		"/lab/[labCode]/batch/create": [~76,[15]],
		"/lab/[labCode]/batch/list": [~77,[15]],
		"/lab/[labCode]/checklist": [~78,[15]],
		"/lab/[labCode]/clear_submit_bar_codes": [~79,[15]],
		"/lab/[labCode]/errored-orders": [~80,[15]],
		"/lab/[labCode]/lens/material": [~81,[15]],
		"/lab/[labCode]/print/forms": [~82,[15]],
		"/lab/[labCode]/qc": [~83,[15]],
		"/lab/[labCode]/[orderId]/diagnosticSet/qc": [~73,[15]],
		"/lab/[labCode]/[orderId]/print/forms": [~74,[15]],
		"/lab/[labCode]/[orderId]/qc": [~75,[15]],
		"/lab/[labCode]/[orderId]/[eye]/lens/update": [~69,[15]],
		"/lab/[labCode]/[orderId]/[eye]/qc": [~70,[15]],
		"/lab/[labCode]/[orderId]/[eye]/schematic": [~71,[15,16]],
		"/lab/[labCode]/[orderId]/[eye]/schematic/[revision]": [~72,[15,16]],
		"/login": [84,[17]],
		"/orders/[orderId]/lens/[eye]/profile": [~85],
		"/troubleshoot": [~86,[18]],
		"/troubleshoot/orders": [~87,[18]],
		"/v1/kb": [~88],
		"/v1/kb/[slug]": [~89]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';